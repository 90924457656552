<template>
    <div>
        <profile-selector
            v-if="showProfileSelector"
            :profiles="profiles"
            :activeProfile="data"
            :activeProfileIndex="activeProfileIndex"
            :loading="loading"
            @togglePopUp="togglePopUp"
            @setActiveProfile="setActiveProfile"
            @deleteProfile="deleteProfile"

        />
        <logo-upload
            :style="!showProfileSelector ? { paddingTop: '42px' } : {}"
            @togglePopUp="togglePopUp"
            @setActiveLogo="setActiveLogo"
            @removeBackground="removeBackground"
            :logo-deleted="data.logoDeleted"
            :logo="data.logo_data"

        />
        <logo-show
            :logo="data.logo_data"
            @togglePopUp="togglePopUp"
            @updateLogoScale="updateLogoScale"
            @deleteLogoImage="deleteLogoImage"
            @changeLogoBgColor="changeLogoBgColor"
            @changeLogoBgColorTransparency="changeLogoBgColorTransparency"
            @setActiveLogo="setActiveLogo"
        />
        <!--Insert the color picker -->
        <color-palette
            :data="data.color_palette"
            @togglePopUp="togglePopUp"
            @updateColorPaletteColor="updateColorPaletteColor"
        />
        <choose-fonts
            :data="data.fonts_data"
            @togglePopUp="togglePopUp"
            @updateFontHeadline="updateFontHeadline"
            @updateFontText="updateFontText"
        />
        <brand-data
            :data="data.brand_data"
            @togglePopUp="togglePopUp"
            @toggleActiveBrandTabs="toggleActiveBrandTabs"
            @brand_name_change="brandDataNameChange"
            @brand_slogan_change="brandDataSloganChange"
            @brand_website_change="brandDataWebsiteChange"
        />
        <div class="left_side_footer_spacer clear"></div>
    </div>
</template>

<script>
import ProfileSelector from './ProfileSelector.vue';
import LogoUpload from "./Logo/Upload.vue";
import LogoShow from "./Logo/Show.vue";
import ColorPalette from "./ColorPalette.vue";
import ChooseFonts from "./ChooseFonts.vue";
import BrandData from "./BrandData.vue";

export default {
    name: "Index",
    components: {
        ProfileSelector,
        LogoUpload,
        LogoShow,
        ColorPalette,
        ChooseFonts,
        BrandData,
    },
    props: {
        showProfileSelector: {
            type: Boolean,
            default: true
        },
        profiles: {
            type: Array,
            default: () => {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default: true
        },
        activeProfileIndex: {
            type: Number,
            default: () => {
                return null;
            }
        },
        data: {
            type: Object,
            default: () => {
                return {
                    //logo
                    logoDeleted: false,
                    logo_data: {
                        src: '../images/editor/yourlogo.png',
                        scale: 80,
                        color: null
                    },
                    //color palette
                    color_palette: {
                        background: '#FF1493',
                        text: '#FFFFFF',
                        elements: '#911D5B'
                        //elements: '#8014FF'
                    },
                    //fonts
                    fonts_data: {
                        headline: 'Josefin Sans',
                        text: 'Josefin Sans'
                    },
                    //brand data
                    brand_data: {
                        active_tabs: ['brand_name'],
                        brand_name: '<p>BrandName</p>',
                        brand_slogan: '<p>Write your slogan here</p>',
                        brand_website: '<p>YourWebpage.com</p>'
                    },
                };
            }
        },
        scenes: {
            type: Array,
            default: () => {
                return [];
            }
        },
    },
    created() {
        this.$emit('setCurrentScene', this.getSceneByName('Profile'));
    },
    mounted() {
        axios.get('/api/user-profile')
            .then(response => {
                for (let i = 0; i < response.data.length; i++) {
                    this.$emit('addProfile', response.data[i]);
                }
                this.$emit('profilesLoaded');
            });
    },
    beforeDestroy() {
        let isDuplicated = false;
        if (this.profiles.length) {
            for (let i = 0; i < this.profiles.length; i++) {
                if (JSON.stringify(this.data) === this.profiles[i].json) {
                    isDuplicated = true;
                }
            }
        }
        if (!isDuplicated) {
            let formData = new FormData();
            formData.append('json', JSON.stringify(this.data));
            axios.post('/api/user-profile', formData)
                .then(response => {
                    this.profiles.push(response.data);
                    //do something with the response if needed
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    methods: {
        //popup
        togglePopUp() {
            this.$emit('togglePopUp');
        },
        //header
        changeAspectRatio(ratio) {
            this.aspectRatio = ratio;
        },
        //profile
        setActiveProfile(profile, index) {
            this.profiles[index] = profile;
            this.$emit('setActiveProfile', profile, index)
        },
        deleteProfile(id) {
            this.$emit('deleteProfile', id)
        },
        //logo
        setActiveLogo(logo) {
            this.$emit('setActiveLogoSrc', logo);
            this.setLogoDeleted(false)
        },
        removeBackground(file) {
            this.$emit('removeBackground', file);
        },
        updateLogoScale(scale) {
            this.$emit('updateLogoScale', scale)

        },
        deleteLogoImage() {
            this.$emit('deleteLogoImage');
            this.setLogoDeleted(true)
        },
        setLogoDeleted(logoDeleted) {
            this.data.logoDeleted = logoDeleted;
        },
        changeLogoBgColor(color) {
            this.$emit('changeLogoBgColor', color);

        },
        changeLogoBgColorTransparency(value) {
            this.$emit('changeLogoBgColorTransparency', value);

        },
        //color palette
        updateColorPaletteColor(type, color) {
            this.$emit('updateColorPaletteColor', type, color);

        },
        //Fonts
        updateFontHeadline(fontName) {
            this.$emit('updateFontHeadline', fontName);

        },
        updateFontText(fontName) {
            this.$emit('updateFontText', fontName);

        },
        ///brand data
        toggleActiveBrandTabs(value) {
            this.$emit('toggleActiveBrandTabs', value)
        },
        brandDataNameChange(event) {
            this.$emit('brandDataNameChange', event.target.value);

        },
        brandDataSloganChange(event) {
            this.$emit('brandDataSloganChange', event.target.value);

        },
        brandDataWebsiteChange(event) {
            this.$emit('brandDataWebsiteChange', event.target.value);

        },
        getSceneByName(name) {
            return this.scenes.find(scene => scene.name === name)
        },
        //footer
        goToNextStep() {
            this.activeStep += 1;
        },
        goToPreviousStep() {
            this.activeStep -= 1;
        }
    }
}
</script>

<style scoped>

</style>
