<template>

    <!--START NEW SELECT PROFILE-->
    <!--FLORINXXX -> CLICK ON .editor_profile_title -> ADD .active ON .editor_profile-->
    <!--FLORINXXX -> ADD .active ON THE SELECTED PROFILE-->
    <div class="editor_block editor_profile" style="padding-top: 42px;"
         :class="[{ 'active': showProfileSelector }, {'profiles-loading':loading}]"
    >
        <div class="editor_foreach_header">
            <div class="editor_profile_title"
                 @click="toggleShowProfileSelector"
            >
                <span v-html="profileName"></span>
                <div class="material-icons ept_down">arrow_drop_down</div>
                <div class="material-icons ept_up">arrow_drop_up</div>
                <div class="lds-ring lds-ring4 lds-ring-profile">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="editor_foreach_actions clr none">
                <div
                    title="Delete Profile"
                    class="efa_action efa_delete"
                    v-if="this.profiles.length > 1 && this.activeProfileIndex!==null"
                    @click="deleteProfile"
                >
                    <div><span class="material-icons">delete_forever</span></div>
                </div>
            </div>
        </div>

        <ul class="editor_my_profiles">
            <li v-for="(profile, index) in profiles" 
                :key="index">
                <div class="open_popup_1"
                     :class="{ active: activeProfileIndex === index }"
                     @click="setActiveProfile(profile, index)">
                    <div>Profile #{{ profiles.length - index }}</div>
                </div>
            </li>
        </ul>
    </div>
    <!--END NEW SELECT PROFILE-->
</template>

<script>
export default {
    name: "ProfileSelector",
    data: () => {
        return {
            showProfileSelector: false,
            initialProfileSet: false
        }
    },
    props: {
        profiles: {
            type: Array,
            default() {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default: true
        },
        notifyLogoImageDeleted: {
            type: Function
        },
        activeProfile: {
            type: Object,
            default() {
                return {};
            }
        },
        activeProfileIndex: {
            type: Number,
            default: null
        }
    },
    computed: {
        profileName() {
            if (this.activeProfileIndex === null) {
                return 'Custom Profile'
            } else {
                return 'Profile <em>#</em>' + Number(this.activeProfileIndex + 1);
            }
        }
    },
    watch: {
        loading: {
            immediate: false,
            handler(newValue, oldValue) {
                if (!this.initialProfileSet) {
                    this.setInitialProfile();
                    this.initialProfileSet = true;
                }
            }
        }
    },
    methods: {
        setInitialProfile() {
            const href = window.location.href;
            if (!this.profiles || !this.profiles.length || href.endsWith('/edit') || href.endsWith('/use')) {
                return
            }
            const profile = this.profiles.find(Boolean);
            if (!profile) {
                return
            }
            this.$emit('setActiveProfile', profile, this.profiles.indexOf(profile))

        },
        toggleShowProfileSelector() {
            this.showProfileSelector = !this.showProfileSelector;
        },
        setActiveProfile(profile, index) {
            this.toggleShowProfileSelector();
            this.$emit('setActiveProfile', profile, index)
        },
        deleteProfile() {
            this.$emit('deleteProfile', this.profiles[this.activeProfileIndex].id)
        }
    }
}
</script>

<style scoped>

</style>
