<template>
    <div class="onboard_help_button" @click="toggleOnboard" >
        <span>Need </span>Help?
    </div>
    <div :class="{ active: componentOnboard }" class="rl_unbording">
        <div class="onboard_block">
            <div class="onboard_inner">
                <div id="onboard_video" class="onboard_video"/>

                <div class="onboard_continue_block">
                    <div class="onboard_continue_button" @click="toggleOnboard">
                        <div>
                            <em>Continue<dfn> to Generator</dfn></em>
                            <span class="material-icons">chevron_right</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="onboard_close_button" title="Close" @click="toggleOnboard"><div>
            <span class="material-icons">close</span>
        </div></div>
    </div>

    <div :class="aspectRatioCSSClasses"
         class="rl_container rl_container_isAnimatedWallpaper"
    >
        <div
            v-if="isSaving"
            class="videoIsSaving"
        >
            <div class="iframe_loader">
                <div class="iframe_loader_bg">
                    <div class="lds-ring lds-ring12">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <span class="lds-image lds-lion"></span><span class="lds-image lds-play"></span></div>
                </div>
                <div class="videoIsSaving_message" v-html="mainLoaderMessage"></div>

            </div>
        </div>
        <Header
            :ratio="aspectRatio"
            @updateAspectRatio="updateAspectRatio"
        />

        <!--START EDITOR LEFT SIDE-->
        <div class="editor_leftside clr">
            <div v-if="data.active_profile" class="editor_leftside_inner">
                <profile
                    v-if="steps[activeStep].name === 'Profile' && scenesTemplates.length > 0"
                    :activeProfileIndex="activeProfileIndex"
                    :data="data.active_profile"
                    :loading="loadingProfiles"
                    :profiles="profiles"
                    :scenes="scenesTemplates"
                    @addProfile="addNewProfile"
                    @brandDataNameChange="brandDataNameChange"
                    @brandDataSloganChange="brandDataSloganChange"
                    @brandDataWebsiteChange="brandDataWebsiteChange"
                    @changeLogoBgColor="changeLogoBgColor"
                    @removeBackground="removeBackground"
                    @changeLogoBgColorTransparency="changeLogoBgColorTransparency"
                    @deleteLogoImage="deleteLogoImage"
                    @deleteProfile="deleteProfile"
                    @profilesLoaded="profilesLoaded"
                    @setActiveLogoSrc="setActiveLogoSrc"
                    @setActiveProfile="updateActiveProfile"
                    @setCurrentScene="setCurrentScene"
                    @toggleActiveBrandTabs="toggleActiveBrandTabs"
                    @togglePopUp="togglePopupVisibility"
                    @updateColorPaletteColor="updateColorPaletteColor"
                    @updateFontHeadline="updateFontHeadline"
                    @updateFontText="updateFontText"
                    @updateLogoScale="updateLogoScale"
                />

                <animated-bg-component
                    v-if="steps[activeStep].name === 'Bg' && scenesTemplates.length > 0"
                    :backgroundColor="data.active_profile.logo_data.color"
                    :currentScene="'Animated Bg'"
                    :data="data.animated_background_data"
                    :scenes="scenesTemplates"
                    :uniqueID="uniqueID"
                    @setCurrentScene="setCurrentScene"
                    @togglePopUp="togglePopupVisibility"
                    @updateBgLength="updateBgLength"
                    @updateBgOpacity="updateBgOpacity"
                    @updateImageBg="updateImageBg"
                />
                <video-settings
                    ref="videoSettingsComponent"
                    v-if="steps[activeStep].name === 'Settings' && scenesTemplates.length > 0"
                    :activeChild="settingsActiveTab"
                    :data="data.video_settings_data"
                    :scenes="scenesTemplates"
                    :selectedScenes="this.data.scenes"
                    @resetFilters="resetFilters"
                    @setActiveChild="setSettingsActiveTab"
                    @setCurrentScene="setCurrentScene"
                    @toggleBorder="toggleBorder"
                    @toggleOverlay="toggleOverlay"
                    @togglePopUp="togglePopupVisibility"
                    @toggleWatermark="toggleWatermark"
                    @updateActiveSong="updateActiveSong"
                    @updateBorderOpacity="updateBorderOpacity"
                    @updateBorderScale="updateBorderScale"
                    @updateBorderSize="updateBorderSize"
                    @updateFiltersBrightness="updateFiltersBrightness"
                    @updateFiltersContrast="updateFiltersContrast"
                    @updateFiltersGrayscale="updateFiltersGrayscale"
                    @updateFiltersHue="updateFiltersHue"
                    @updateFiltersInvert="updateFiltersInvert"
                    @updateFiltersSaturate="updateFiltersSaturate"
                    @updateFiltersSepia="updateFiltersSepia"
                    @updateOverlayOpacity="updateOverlayOpacity"
                    @updateVideoScaleX="updateVideoScaleX"
                    @updateVideoScaleY="updateVideoScaleY"
                    @updateVideoSpeed="updateVideoSpeed"
                    @updateVideoTiming="updateVideoTiming"
                    @updateWatermarkPositionX="updateWatermarkPositionX"
                    @updateWatermarkPositionY="updateWatermarkPositionY"
                    @updateWatermarkScale="updateWatermarkScale"
                />
            </div>
        </div>
        <!--START EDITOR RIGHT SIDE-->
        <div class="editor_rightside clr">
            <Iframes
                v-if="scenesTemplates.length && iframeUrl"
                ref="iframesComponent"
                :currentScene="currentScene"
                :favored="formattedFavorites"
                :iframeError="isIframeErrorPresent"
                :iframeErrorMessage="iframeErrorMessage"
                :iframeKey="iframeKey"
                :iframeUrl="iframeUrl"
                :iframes="iframes"
                :loading="loading"
                :sceneIdName="computeSceneIdName()"
                :selectedSceneIndex="Number(currentSelectedSceneIndex)"
                :selectedScenes="this.data.scenes"
                @iframeLoaded="iframeLoaded"
                @iframeLoading="iframeLoading"
                @selectIframe="selectScene"
                @toggleFavored="toggleFavoriteStatus"
            />
        </div>
        <!--END EDITOR RIGHT SIDE-->
        <Footer
            v-if="scenesTemplates.length > 0"
            :activeStep="activeStep"
            :activeStepTitle="activeStepTitle"
            :isNextStepAllowed="isNextStepAllowed"
            :loading="loading"
            :steps="steps"
            @allStepsAreFinished="finalizeAllSteps"
            @goToNextStep="navigateToNextStep"
            @goToPreviousStep="navigateToPreviousStep"
        />
    </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Profile from '../components/Profile/Index.vue'
import Background from '../components/Background/Index.vue';
import AnimatedBgComponent from '../components/AnimatedBg/Index.vue';
import VideoSettings from '../components/VideoSettings/Index.vue';
// import Iframes from '../components/Iframes/Iframes.vue';
import {v4 as uuidv4} from 'uuid';
import Iframes from '../components/Iframes/Index.vue';
import {handlePopupToggle, requestDeleteFavorite} from "../helpers/index.js";


export default {
    name: "AnimatedWallpaper",
    components: {
        Header,
        Footer,
        Profile,
        Background,
        AnimatedBgComponent,
        VideoSettings,
        Iframes
    },
    data: () => {
        return {
            isTogglingPopUp:false,
            // Unique Identifiers
            uniqueID: uuidv4(),
            iframeKey: uuidv4(),
            timeoutId: null,
            player: null,
            videoId:"N6LcP2QJI2g",

            // UI State
            loading: true,
            loadingProfiles: true,
            isSaving: false,
            mainLoaderMessage:'Saving to Roarrr!',
            popup: false,
            aspectRatio: 'square',

            // Profiles and Scenes
            activeProfileIndex: null,
            profiles: [],
            scenesTemplates: [],
            timeInSconds: 0,

            // Scene Data
            data: {
                // Contains active_profile, animated_background_data, video_settings_data, and scenes
                active_profile: {
                    //logo
                    logo_data: {
                        src: '../images/editor/yourlogo.png',
                        scale: 80,
                        color: null,
                        isTransparentBg: true
                    },
                    //color palette
                    color_palette: {
                        background: '#FF1493',
                        text: '#FFFFFF',
                        elements: '#911D5B'
                    },
                    //fonts
                    fonts_data: {
                        headline: 'Fredoka One',
                        text: 'Fredoka One'
                    },
                    //brand data
                    brand_data: {
                        active_tabs: ['brand_name'],
                        brand_name: '<p>BrandName</p>',
                        brand_slogan: '<p>Write your slogan here</p>',
                        brand_website: '<p>YourWebpage.com</p>'
                    },
                },
                animated_background_data: {
                    opacity: 50,
                    length: 30,
                    image: null
                },
                video_settings_data: {
                    speed_and_timing: {
                        speed: 1,
                        timing: 'Default'
                    },
                    scale: {
                        x: 100,
                        y: 100
                    },
                    watermark: {
                        active: false,
                        scale: 100,
                        x: 100,
                        y: 100
                    },
                    filters: {
                        brightness: 100,
                        contrast: 100,
                        grayscale: 0,
                        hue: 0,
                        invert: 0,
                        saturate: 100,
                        sepia: 0
                    },
                    overlay: {
                        active: false,
                        opacity: 50,
                    },
                    border: {
                        active: false,
                        size: 20,
                        scale: 90,
                        opacity: 0
                    },
                    sound: {
                        generatedId: null,
                        title: null,
                        src: null
                    }
                },
                scenes: {
                    animatedbgIframeId: null,
                    backgroundAnimeIframeId: null,
                    backgroundStaticIframeId: null,
                    overlayIframeId: null,
                    borderIframeId: null,
                    watermarkIframeId: null,
                }
            },

            // Settings and Steps
            settingsActiveTab: 'SpeedAndTiming',
            activeStep: 0,
            activeStepTitle: 'Animated Wallpaper Generator',
            steps: [
                {
                    name: 'Profile',
                    additionalClasses: 'step_progress_0',
                    html: '<div><span>[<strong>1</strong>/3]</span> Profile Setup</div>',
                    skippable: false,
                },
                {
                    name: 'Bg',
                    additionalClasses: 'step_progress_33',
                    html: '<div><span>[<strong>2</strong>/3]</span> Background Selection</div>',
                    skippable: false,
                },
                {
                    name: 'Settings',
                    additionalClasses: 'step_progress_66',
                    html: '<div><span>[<strong>3</strong>/3]</span> Video Settings</div>',
                    skippable: false,
                }
            ],
            skipped: {
                intro: true,
                outro: true
            },

            // Iframe and Favorites
            iframes: [],
            iframeUrl: null,
            favorites: null,
            iframeErrorMessage: null,

            // Responsive Design
            windowWidth: window.innerWidth,

            // Current Scene
            currentScene: null,
            refreshID: uuidv4(),
            componentOnboard:false
        }
    },
    props: {
        initialData: {
            type: String,
            default: '{"type":"create"}'
        },
        typeData: {
            type: String
        },
        showOnboard: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        // Scene Index and Names
        // ---------------------------------------

        // Gets the current selected scene's index from the data.
        currentSelectedSceneIndex() {
            let sceneId = this.data.scenes[this.computeSceneIdName()];
            return sceneId || null;
        },

        // Formats the current scene's name into a slug (lowercase and hyphenated).
        formattedCurrentSceneName() {
            if (this.currentScene) {
                return this.currentScene.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
            }
            return null;
        },

        // Initial Data Parsing
        // ---------------------------------------

        // Parses the initial data provided to the component.
        parsedInitialData() {
            return JSON.parse(this.initialData);
        },

        // UI Related Computed Properties
        // ---------------------------------------

        // Computes CSS classes for aspect ratio and popup visibility.
        aspectRatioCSSClasses() {
            const classMap = {'square': 'rl_is_square', 'portrait': 'rl_is_portrait', 'landscape': 'rl_is_landscape'};
            const aspectClass = classMap[this.aspectRatio] || classMap['portrait'];
            return {[aspectClass]: true, 'popup-is-opened': this.popup};
        },

        // Determines the size of the iframe based on the window width.
        computedIframeSize() {
            if (this.windowWidth > 1280) return 480;
            if (this.windowWidth <= 1280 && this.windowWidth > 1024) return 480;
            return 320;
        },

        // Favorites and URLs
        // ---------------------------------------

        // Transforms favorites data into a usable format with URLs.
        formattedFavorites() {
            if (!this.currentScene || !this.favorites || !(this.formattedCurrentSceneName in this.favorites)) {
                return [];
            }
            return this.favorites[this.formattedCurrentSceneName].map(item => ({
                index: item.index,
                url: this.getUrl(this.currentScene.name, item.index)
            }));
        },

        // Determines if navigation to the next step is allowed.
        isNextStepAllowed() {
            if (this.steps[this.activeStep].name === 'Profile' && this.data.scenes.profileIframeId && this.data.active_profile.logo_data.src) return true;
            if (this.steps[this.activeStep].name === 'Bg' && this.data.scenes.animatedbgIframeId) return true;
            if (this.steps[this.activeStep].name === 'Settings') return true;
            return false;
        },

        // Checks if there's an error with the iframe (e.g., missing logo).
        isIframeErrorPresent() {
            if (this.data.active_profile.logo_data && !this.data.active_profile.logo_data.src) {
                this.iframeErrorMessage = 'Upload your Logo/Avatar';
                return true;
            }
            this.iframeErrorMessage = null;
            return false;
        },
    },
    provide() {
        return {
            triggerBackgroundRemoval: this.triggerBackgroundRemoval
        };
    },
    mounted(){
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // Setup the callback to initialize the player after the API is loaded
            window.onYouTubeIframeAPIReady = this.initializeYouTubePlayer;
        } else {
            this.initializeYouTubePlayer();
        }
    },
    created() {
        this.componentOnboard=this.showOnboard;

        this.generateRandomIdsForProfile();
        this.initializeComponent();
        this.fetchScenesTemplates();
        this.retrieveFavoritesData();
        window.addEventListener('resize', this.handleWindowResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    watch: {
        'data.active_profile':
            {
                handler(newProfile, oldProfile) {
                    if (newProfile === oldProfile) {
                        this.activeProfileIndex = null;
                    }
                },
                deep: true
            },
        currentScene: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.refreshSceneUrls();
                }
            },
            // deep: true // Use deep watcher if currentScene is an object
        }
    },
    methods: {
        // Initialization Methods
        // ----------------------
        initializeYouTubePlayer() {
            this.player = new YT.Player('onboard_video', {
                videoId: this.videoId
            });
        },
        // These methods are primarily responsible for setting up the component state when it is created.

        async initializeComponent() {
            await this.fetchInitialData();
            await this.saveTemporaryData();
        },
        triggerMainLoader(isSaving,mainLoaderMessage='Saving to Roarrr!') {
            this.isSaving =isSaving;
            this.mainLoaderMessage=mainLoaderMessage;
        },
        triggerBackgroundRemoval(isSaving){
            this.triggerMainLoader(isSaving,'Hunting Pixels <div class="hunting_pixels">Removing the image background may extend the hunt by a few minutes!<div>')
        },
        async fetchInitialData() {
            if (!['edit', 'use'].includes(this.parsedInitialData.type)) return;
            try {
                let response = await axios.get(`/api/animation/${this.parsedInitialData.animation}`);
                this.data = JSON.parse(response.data);
                this.refreshSceneUrls();
            } catch (error) {
                console.error('Error in fetchInitialData:', error);
                // Set an error message in your data and display it in the template
                this.errorMessage = "Failed to fetch initial data.";
            }
        },
        removeBackground(image) {
            this.data.active_profile.logo_data.src = image;
            this.triggerDataSave();
        },
        fetchScenesTemplates() {
            axios.get('/api/admin/scene-templates')
                .then(response => {
                    this.scenesTemplates = JSON.parse(response.data);
                });
        },

        generateRandomIdsForProfile() {
            this.randomAnimatedBgId = Math.floor(Math.random() * 700) + 1;
            this.randomAnimatedLogoId = Math.floor(Math.random() * 401) + 400;
        },
        // UI Update Methods
        // -----------------
        // These methods are responsible for updating the UI state, like toggling visibility and updating aspect ratio.

        togglePopupVisibility() {
            handlePopupToggle(this)
        },

        updateAspectRatio(ratio) {
            this.aspectRatio = ratio;
            this.generateUniqueIdForIframes();
            this.iframeLoading();
        },
        // Navigation Methods
        // ------------------
        // Methods to handle navigation through steps or resizing the window.

        navigateToNextStep() {
            this.activeStep += 1;
            this.finishDelay();
        },
        finishDelay() {
            if (this.steps.length === this.activeStep + 1) {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, 1000)
            }
        },
        navigateToPreviousStep() {
            this.activeStep -= 1;
        },

        handleWindowResize() {
            let windowInnerWidth = window.innerWidth;
            if (((this.windowWidth - windowInnerWidth) > 50) || (windowInnerWidth - this.windowWidth) > 50) {
                this.windowWidth = windowInnerWidth;
                this.refreshIframeUrl();
                this.refreshSceneUrls();
            }
        },

       toggleOnboard(){
            this.componentOnboard=!this.componentOnboard;
            if(!this.componentOnboard){
                this.player.pauseVideo();
            }
        },
        // API Interaction and Data Persistence Methods
        // --------------------------------------------
        // These methods deal with saving data to the server and fetching data from APIs.
        async requestUpdateOnboard() {
            try {
                if(!this.showOnboard){
                    return;
                }
                let response = await axios.post(`/api/update-onboard`,{
                    page:this.typeData
                });
                if(response.data.success){
                    this.componentOnboard=false;
                    return;
                }
                console.log('error updating onboard',response.data.message)

            } catch (error) {
                console.error('Error in fetchInitialData:', error);

            }
        },
        async saveTemporaryData() {
            if (this.uniqueID) {
                try {
                    await axios.post('/api/animation/store-temp', {
                        id: this.uniqueID,
                        json: JSON.stringify(this.data)
                    });
                    this.generateUniqueIdForIframes();
                } catch (error) {
                    console.error('Error in saveTemporaryData:', error);
                }
            }
        },
        triggerSoundStop() {
            if (this.$refs.videoSettingsComponent) {
                this.$refs.videoSettingsComponent.stopSoundInChild();
            }
        },
        async executeAnimationSave() {
            this.triggerSoundStop();
            const url = this.buildAnimationApiUrl();
            this.triggerMainLoader(true)

            try {
                const response = await axios.post(url, {
                    json: JSON.stringify(this.data),
                    url: this.sceneUrl,
                    time: this.timeInSconds,
                    type: this.typeData
                });
                     window.location.href = response.data.link;
            } catch (error) {
                this.triggerMainLoader(false)

                this.errorMessage = error.errorMessage;
                console.error('Error in executeAnimationSave:', error);
            }
        },

        retrieveFavoritesData() {
            axios.get('/api/scene/favored')
                .then((response) => {
                    this.favorites = response.data;
                });
        },
        // Profile Management Methods
        // ---------------------------
        // Methods for managing user profiles, including adding, updating, and persisting them.
        profilesLoaded() {
            this.loadingProfiles = false;
        },

        addNewProfile(profile) {
            this.profiles.push(profile);
        },

        deleteProfile(id) {
            axios.delete(`/api/user-profile/${id}`)
                .then(response => {
                    const index = this.profiles.findIndex(profile => profile.id === id);
                    this.profiles.splice(index, 1);
                    this.activeProfileIndex = null;
                });
        },

        updateActiveProfile(profile, index) {
            this.data.active_profile = JSON.parse(profile.json);
            this.activeProfileIndex = index;
            this.triggerDataSave();
        },

        async persistUserProfile() {
            let isDuplicated = false;
            this.profiles.forEach(profile => {
                if (JSON.stringify(this.data.active_profile) === profile.json) {
                    isDuplicated = true;
                }
            });
            if (!isDuplicated) {
                let formData = new FormData();
                formData.append('json', JSON.stringify(this.data.active_profile));
                await axios.post('/api/user-profile', formData)
                    .then(response => {
                        this.addNewProfile(response.data);
                    });
            }
        },


        refreshSceneUrls() {
            if (!this.currentScene || this.currentScene.name === 'Profile') {
                return;
            }

            const randomIframesIds = this.createRandomIframeIds(this.currentScene.startID, this.currentScene.endID, 500);

            this.iframes = randomIframesIds.map(index => ({
                index,
                url: this.getUrl(this.currentScene.name, index)
            }));

            this.addSelectedIframeAtStart();
        },
        computeSceneIdName() {
            if (!this.currentScene) return null;
            return this.currentScene.name.trim().split(/\s+/)
                .map((word, index) => index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1))
                .join('') + 'IframeId';
        },
        triggerDataSave() {
            this.iframeLoading();
            // Clear any existing timeout
            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
            }
            //call the api to save the data and then refresh the id
            this.timeoutId = setTimeout(() => {
                this.saveTemporaryData()
            }, 2000);
            this.generateRandomIdsForProfile();
        },

        checkIfStepIsSkipped(step) {
            let stepLower = step.toLowerCase();
            if (stepLower in this.skipped) {
                return this.skipped[stepLower];
            }
            return false;
        },


        async finalizeAllSteps() {
            this.loading = true;
            await this.requestUpdateOnboard();
            // await this.persistUserProfile();
            await this.executeAnimationSave();
        },

        // Iframe Interaction Methods
        // ---------------------------
        // Methods for managing iframes, including loading states and setting the current scene.

        iframeLoaded(numberOfFrames) {
            this.loading = false;
            this.timeInSconds = numberOfFrames;
        },
        iframeLoading() {
            this.loading = true;
        },
        setCurrentScene(scene) {
            const sceneName = scene ? scene.name : null;
            const currentSceneName = this.currentScene ? this.currentScene.name : null;

            const isSceneChangeRequired = sceneName !== null ||
                (currentSceneName !== null && currentSceneName !== sceneName);

            if (isSceneChangeRequired) {
                this.currentScene = scene;
            }
        },
        generateUniqueIdForIframes() {
            this.refreshID = uuidv4();
            this.refreshIframeUrl();
        },

        // Scene Management Methods
        // ------------------------
        // Methods for managing scenes, including fetching scene details and updating URLs.

        getSceneByName(name) {
            return this.scenesTemplates.find(scene => scene.name === name);
        },
        getUrl(sceneType = null, id = null) {
            let urlsPaths = [];
            let animatedBgScene = this.getSceneByName('AnimatedBg');
            let overlayScene = this.getSceneByName('Overlay');
            let borderScene = this.getSceneByName('Border');
            let watermarkScene = this.getSceneByName('Watermark');
            if (animatedBgScene && overlayScene && borderScene && watermarkScene) {
                urlsPaths = [
                    {
                        'id': (sceneType && sceneType === 'AnimatedBg') ? id : this.data.scenes.animatedbgIframeId,
                        'name': 'Animated Bg',
                        'url': animatedBgScene.type + '.' + animatedBgScene.folder + '.' + ((sceneType && sceneType === 'AnimatedBg') ? id : this.data.scenes.animatedbgIframeId),
                    },
                    {
                        'id': (sceneType && sceneType === 'Overlay') ? id : this.data.scenes.overlayIframeId,
                        'name': 'Overlay',
                        'url': overlayScene.type + '.' + overlayScene.folder + '.' + ((sceneType && sceneType === 'Overlay') ? id : this.data.scenes.overlayIframeId),
                    },
                    {
                        'id': (sceneType && sceneType === 'Border') ? id : this.data.scenes.borderIframeId,
                        'name': 'Border',
                        'url': borderScene.type + '.' + borderScene.folder + '.' + ((sceneType && sceneType === 'Border') ? id : this.data.scenes.borderIframeId),
                    },
                    {
                        'id': (sceneType && sceneType === 'Watermark') ? id : this.data.scenes.watermarkIframeId,
                        'name': 'Watermark',
                        'url': watermarkScene.type + '.' + watermarkScene.folder + '.' + ((sceneType && sceneType === 'Watermark') ? id : this.data.scenes.watermarkIframeId),
                    }
                ]
            }
            const baseUrl = "/player?";
            const params = [
                `video_size=${this.aspectRatio}`,
                `video_scale=${this.computedIframeSize}`,
                `video_data=partial`,
                `video_display=preview`,
                `video_prev=preview`
            ];

            urlsPaths.forEach((item) => {
                if (item.id !== null && item.name !== 'Profile' && (!this.checkIfStepIsSkipped(item.name) || this.getSceneByName() !== item.name)) {
                    params.push(`scenes[]=${item.url}`)
                }
            });

            params.push(`refreshID=${this.refreshID}`);
            params.push(`dataID=${this.uniqueID}`);

            return baseUrl + params.join("&");
        },
        selectScene(payload, favored = false) {
            if (payload.type === 'Profile'
                || (favored && this.data.scenes[this.computeSceneIdName()] === payload.iframe)) {
                return;
            }
            if(this.data.scenes[this.computeSceneIdName()] !== payload.iframe){
                this.data.scenes[this.computeSceneIdName()] = payload.iframe;
                this.refreshIframeUrl();
            }
        },
        refreshIframeUrl() {
            if (this.isIframeErrorPresent) return;
            this.iframeLoading();
            const hasNonNullScene = Object.values(this.data.scenes).some(item => item !== null);
            let urlsPaths = [];
            const baseUrl = "/player?";

            const params = [
                `video_size=${this.aspectRatio}`,
                `video_scale=${this.computedIframeSize}`,
                `video_data=partial`,
                `video_display=preview`,
                `video_prev=preview`
            ];

            if (!hasNonNullScene) {
                params.push(`scenes[]=unique.iAnimatedBg.${this.randomAnimatedBgId}`)
                params.push(`scenes[]=unique.iLogoData.${this.randomAnimatedLogoId}`)

                params.push(`refreshID=${this.refreshID}`);
                params.push(`dataID=${this.uniqueID}`);
                this.iframeUrl = baseUrl + params.join("&");
            } else {
                let animatedBgScene = this.getSceneByName('AnimatedBg');
                let overlayScene = this.getSceneByName('Overlay');
                let borderScene = this.getSceneByName('Border');
                let watermarkScene = this.getSceneByName('Watermark');
                if (animatedBgScene && overlayScene && borderScene && watermarkScene) {
                    urlsPaths = [
                        {
                            'id': this.data.scenes.animatedbgIframeId,
                            'name': 'Animated Bg',
                            'url': animatedBgScene.type + '.' + animatedBgScene.folder + '.' + this.data.scenes.animatedbgIframeId,
                        },
                        {
                            'id': this.data.scenes.overlayIframeId,
                            'name': 'Overlay',
                            'url': overlayScene.type + '.' + overlayScene.folder + '.' + this.data.scenes.overlayIframeId,
                        },
                        {
                            'id': this.data.scenes.borderIframeId,
                            'name': 'Border',
                            'url': borderScene.type + '.' + borderScene.folder + '.' + this.data.scenes.borderIframeId,
                        },
                        {
                            'id': this.data.scenes.watermarkIframeId,
                            'name': 'Watermark',
                            'url': watermarkScene.type + '.' + watermarkScene.folder + '.' + this.data.scenes.watermarkIframeId,
                        }
                    ]
                }

                urlsPaths.forEach((item) => {
                    if (item.id !== null && item.name !== 'Profile') {
                        params.push(`scenes[]=${item.url}`)
                    }
                });

                params.push(`refreshID=${this.refreshID}`);
                params.push(`dataID=${this.uniqueID}`);
                this.iframeUrl = baseUrl + params.join("&");
            }
            this.iframeKey = uuidv4();
        },

        // Utility Methods
        // ---------------
        // Miscellaneous utility methods used throughout the application.

        createRandomIframeIds(startID, endID, count) {
            return Array.from({length: count}, () => Math.floor(Math.random() * (endID - startID + 1)) + startID);
        },
        addSelectedIframeAtStart() {
            const selectedIndex = this.data.scenes[this.computeSceneIdName()];
            if (selectedIndex) {
                const selectedIframe = {
                    index: selectedIndex,
                    url: this.getUrl(this.currentScene.name, selectedIndex)
                };
                this.iframes.unshift(selectedIframe);
            }
        },

        // Favorites Management Methods
        // ----------------------------
        // Methods to manage the favorite scenes and toggling their status.

        async toggleFavoriteStatus(index) {
            let favoredType = this.formattedCurrentSceneName;
            if (!this.favorites) {
                this.favorites = {};
            }
            if (!this.favorites.hasOwnProperty(favoredType)) {
                this.favorites[favoredType] = [];
            }
            if (this.favorites[favoredType]) {
                const itemIndex = this.favorites[favoredType].findIndex(item => item.index === index);
                if (itemIndex !== -1) {
                    this.favorites[favoredType].splice(itemIndex, 1);
                    await axios.post(`/api/scene/toggle-favored`, {index, type: favoredType}).then(() => {
                        this.loading = false;
                    });

                } else {
                    try {
                        await axios.post(`/api/scene/toggle-favored`, {index, type: favoredType})
                            .then((response) => {
                                this.favorites[favoredType].push(response.data);
                                this.loading = false;
                            });
                    } catch (error) {
                        console.error("Error in toggleFavorite:", error);
                        // Handle error, e.g., set an error message
                    }
                }
            } else {
                this.favorites[favoredType] = [];
                this.loading = false;
            }
        },

        // API URL Construction Methods
        // ----------------------------
        // Methods to construct and manage URLs for API calls.

        buildAnimationApiUrl() {
            return '/api/animation' + (this.parsedInitialData.animation && this.parsedInitialData.type === 'edit' ? '/' + this.parsedInitialData.animation : '');
        },

        // Logo and Branding Methods
        // --------------------------
        // Methods related to the logo and branding elements, including setting logo source, scale, and color.

        setActiveLogoSrc(logo) {
            this.data.active_profile.logo_data.src = logo;
            this.triggerDataSave();
        },

        updateLogoScale(scale) {
            this.data.active_profile.logo_data.scale = scale;
            this.triggerDataSave();
        },

        changeLogoBgColor(color) {
            this.data.active_profile.logo_data.color = color;
            this.data.active_profile.logo_data.isTransparentBg = false;
            this.triggerDataSave();
        },

        changeLogoBgColorTransparency(value) {
            this.data.active_profile.logo_data.isTransparentBg = value;
            this.triggerDataSave();
        },

        deleteLogoImage() {
            this.data.active_profile.logo_data.src = null;
            this.triggerDataSave();
        },

        // Color Palette Methods
        // ----------------------
        // Methods for updating the color palette used in the profile.

        updateColorPaletteColor(type, color) {
            this.data.active_profile.color_palette[type] = color;
            this.triggerDataSave();
        },

        // Font Style Methods
        // ------------------
        // Methods for updating font styles for headlines and text.

        updateFontHeadline(fontName) {
            this.data.active_profile.fonts_data.headline = fontName;
            this.triggerDataSave();
        },

        updateFontText(fontName) {
            this.data.active_profile.fonts_data.text = fontName;
            this.triggerDataSave();
        },

        // Brand Data Management Methods
        // -----------------------------
        // Methods for managing brand data, such as name, slogan, and website.

        toggleActiveBrandTabs(brandTab) {
            if (!this.data.active_profile.brand_data.active_tabs.includes(brandTab)) {
                this.data.active_profile.brand_data.active_tabs.push(brandTab);
            } else {
                this.data.active_profile.brand_data.active_tabs.splice(this.data.active_profile.brand_data.active_tabs.indexOf(brandTab), 1);
            }
            this.triggerDataSave();
        },

        brandDataNameChange(brandName) {
            this.data.active_profile.brand_data.brand_name = brandName;
            this.triggerDataSave();
        },

        brandDataSloganChange(brandSlogan) {
            this.data.active_profile.brand_data.brand_slogan = brandSlogan;
            this.triggerDataSave();
        },

        brandDataWebsiteChange(brandWebsite) {
            this.data.active_profile.brand_data.brand_website = brandWebsite;
            this.triggerDataSave();
        },

        // Background Settings Methods
        // ---------------------------
        // Methods for managing background settings like image, opacity, and length.

        updateImageBg(imagePath) {
            this.data.animated_background_data.image = imagePath;
            this.triggerDataSave();
        },

        updateBgOpacity(value) {
            this.data.animated_background_data.opacity = value;
            this.triggerDataSave();
        },

        updateBgLength(value) {
            this.data.animated_background_data.length = value;
            this.triggerDataSave();
        },

        // Video Settings Methods
        // ----------------------
        // Methods related to video settings like speed, timing, scale, and watermark.

        setSettingsActiveTab(tab) {
            this.settingsActiveTab = tab;
        },

        updateVideoSpeed(value) {
            this.data.video_settings_data.speed_and_timing.speed = value;
            this.triggerDataSave();
        },

        updateVideoTiming(value) {
            this.data.video_settings_data.speed_and_timing.timing = value;
            this.triggerDataSave();
        },

        updateVideoScaleX(value) {
            this.data.video_settings_data.scale.x = value;
            this.triggerDataSave();
        },

        updateVideoScaleY(value) {
            this.data.video_settings_data.scale.y = value;
            this.triggerDataSave();
        },

        toggleWatermark(value) {
            this.data.video_settings_data.watermark.active = value;
            if (!value) {
                this.data.scenes.watermarkIframeId = null;
            }
        },

        updateWatermarkScale(value) {
            this.data.video_settings_data.watermark.scale = value;
            this.triggerDataSave();
        },

        updateWatermarkPositionX(value) {
            this.data.video_settings_data.watermark.x = value;
            this.triggerDataSave();
        },

        updateWatermarkPositionY(value) {
            this.data.video_settings_data.watermark.y = value;
            this.triggerDataSave();
        },
        // Filters Methods
        // ---------------
        // Methods to update video filters settings such as brightness, contrast, grayscale, etc.

        updateFiltersBrightness(value) {
            this.data.video_settings_data.filters.brightness = value;
            this.triggerDataSave();
        },

        updateFiltersContrast(value) {
            this.data.video_settings_data.filters.contrast = value;
            this.triggerDataSave();
        },

        updateFiltersGrayscale(value) {
            this.data.video_settings_data.filters.grayscale = value;
            this.triggerDataSave();
        },

        updateFiltersHue(value) {
            this.data.video_settings_data.filters.hue = value;
            this.triggerDataSave();
        },

        updateFiltersInvert(value) {
            this.data.video_settings_data.filters.invert = value;
            this.triggerDataSave();
        },

        updateFiltersSaturate(value) {
            this.data.video_settings_data.filters.saturate = value;
            this.triggerDataSave();
        },

        updateFiltersSepia(value) {
            this.data.video_settings_data.filters.sepia = value;
            this.triggerDataSave();
        },

        resetFilters() {
            this.data.video_settings_data.filters = {
                brightness: 100,
                contrast: 100,
                grayscale: 0,
                hue: 0,
                invert: 0,
                saturate: 100,
                sepia: 0
            };
            this.triggerDataSave();
        },

        // Overlay Methods
        // ---------------
        // Methods for managing the video overlay settings.

        toggleOverlay(value) {
            this.data.video_settings_data.overlay.active = value;
            if (!value) {
                this.data.scenes.overlayIframeId = null;
            }
        },

        updateOverlayOpacity(value) {
            this.data.video_settings_data.overlay.opacity = value;
            this.triggerDataSave();
        },

        // Border Methods
        // --------------
        // Methods to control the video border settings.

        toggleBorder(value) {
            this.data.video_settings_data.border.active = value;
            if (!value) {
                this.data.scenes.borderIframeId = null;
            }
        },

        updateBorderSize(value) {
            this.data.video_settings_data.border.size = value;
            this.triggerDataSave();
        },

        updateBorderScale(value) {
            this.data.video_settings_data.border.scale = value;
            this.triggerDataSave();
        },

        updateBorderOpacity(value) {
            this.data.video_settings_data.border.opacity = value;
            this.triggerDataSave();
        },

        // Sound Methods
        // -------------
        // Method to update the active song for the video settings.

        updateActiveSong(song) {
            this.data.video_settings_data.sound = song;
        },
    }
}
</script>

<style scoped>

</style>
