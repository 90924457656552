<template>
    <div class="convert_block_C">
        <div class="convert_block_D">
            <div :class="['convert_block', currentMode, isActive ? 'convert_PROMPT_ACTIVE' : '']">
                <div class="convert_block_inner">

                    <!-- URL to Video Block -->
                    <div class="convert_block_URL">
                        <form @submit.prevent="submitUrlForm" enctype="multipart/form-data">
                            <input placeholder="https://webpage.com (product, article, blog post)" v-model="urlInput" type="text" minlength="1" class="convert_input_URL" required>
                            <span class="convert_highlight_URL"></span>
                            <span class="convert_bar_URL"></span>
                            <label class="convert_label_URL">{{ currentUrlLabel }}</label>
                            <div class="convert_submit_block_URL">
                                <button class="convert_submit_URL" type="submit" title="Convert URL to Video">
                                    <span class="material-icons">arrow_forward</span>
                                </button>
                            </div>
                        </form>
                        <div v-if="urlErrors.length" class="error-messages">
                            <ul>
                                <li v-for="error in urlErrors" :key="error">{{ error }}</li>
                            </ul>
                        </div>
                    </div>

                    <!-- Prompt to Video Block -->
                    <div class="convert_block_PROMPT">
                        <form @submit.prevent="submitPromptForm" enctype="multipart/form-data">
                            <div class="convert_input_PROMPT_block" @click.stop="activatePrompt"
                                 @dragover.prevent="handleDragOver" @dragleave="handleDragLeave"
                                 @drop.prevent="handleDrop">
                                <div class="convert_input_PROMPT_content">
                                    <textarea placeholder="Write your story, announcement, or any text..." v-model="promptInput" minlength="1" maxlength="25000" rows="1" class="convert_input_PROMPT" @click="activatePrompt"> </textarea>
                                    <label class="convert_label_PROMPT">{{ currentPromptLabel }}</label>
                                    <span class="convert_bar_PROMPT"></span>
                                </div>

                                <!-- Photo Upload -->
                                <div class="convert_photos_block clr">
                                    <div class="convert_photos_content">

                                        <!-- Upload Form -->
                                        <div
                                            :class="['my_upload_block', { active: isUploading, error: uploadError, dragging: isDragging }]">
                                            <div class="my_upload_content">
                                                <div v-if="!isUploading" class="my_upload_content_inner">
                                                    <div class="my_upload_content_left">
                                                        <div v-if="!uploadError"
                                                             class="my_upload_content_txt my_upload_content_normal">
                                                            Use high-resolution images.<br> Drag & drop or click to upload.
                                                        </div>
                                                        <div v-else
                                                             class="my_upload_content_txt my_upload_content_error">
                                                            Invalid format. Accepted only: jpg, jpeg, png, svg, webp
                                                        </div>
                                                    </div>
                                                    <div class="my_upload_content_right">
                                                        <div class="my_upload_content_button" @click="uploadImages">
                                                            Upload
                                                        </div>
                                                        <input ref="fileInput" type="file"
                                                               class="my_upload_content_input" accept="image/*"
                                                               @change="handleFileUpload" multiple>
                                                    </div>
                                                </div>
                                                <div v-if="isUploading" class="my_upload_content_inner_uploading">
                                                    <div class="my_upload_content_left">
                                                        <div class="my_upload_content_txt">Keep calm!<br> We are
                                                            processing your data.
                                                        </div>
                                                    </div>
                                                    <div class="my_upload_content_right">
                                                        <div class="lds-ring lds-ring5">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Images List -->
                                        <div class="convert_photos_list clr">
                                            <div class="editor_images_inner clr">
                                                <ol class="editor_images_list clr">
                                                    <li v-for="(image, index) in images" :key="image.id">
                                                        <div class="editor_img_list_inner">
                                                            <div class="editor_img_list_photo">
                                                                <div
                                                                    :style="{
                                                                       backgroundImage: `url(${image.url})`,
                                                                       transform: `rotate(${image.rotation}deg)`
                                                                    }"
                                                                    class="editor_img_list_photo_now">
                                                                </div>
                                                            </div>
                                                            <div class="editor_img_list_number"></div>
                                                            <div class="editor_img_button_go editor_img_button_goleft"
                                                                 @click="moveImageLeft(index)">
                                                                <span class="material-icons">navigate_before</span>
                                                            </div>
                                                            <div class="editor_img_button_go editor_img_button_goright"
                                                                 @click="moveImageRight(index)">
                                                                <span class="material-icons">navigate_next</span>
                                                            </div>
                                                            <div class="editor_img_button_rotateleft"
                                                                 title="Rotate left"
                                                                 @click="rotateImage(index, 'left')">
                                                                <span class="material-icons">rotate_left</span>
                                                            </div>
                                                            <div class="editor_img_button_rotateright"
                                                                 title="Rotate right"
                                                                 @click="rotateImage(index, 'right')">
                                                                <span class="material-icons">rotate_right</span>
                                                            </div>
                                                            <div class="editor_img_button_delete" title="Delete photo"
                                                                 @click="deleteImage(index)">
                                                                <span class="material-icons">clear</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="convert_PROMPT_close_button" @click.stop="deactivatePrompt">
                                    <span class="material-icons">close</span>
                                </div>
                            </div>
                            <div class="convert_submit_block_PROMPT">
                                <button class="convert_submit_PROMPT" type="submit" title="Convert Prompt to Video">
                                    <span class="material-icons">arrow_forward</span>
                                </button>
                            </div>
                        </form>
                        <div v-if="promptErrors.length" class="error-messages">
                            <ul>
                                <li v-for="error in promptErrors" :key="error">{{ error }}</li>
                            </ul>
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="convert_buttons">
                        <div class="convert_button_URL" @click="showUrlForm"><span>URL</span></div>
                        <div class="convert_button_PROMPT" @click="showPromptForm"><span>Content</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    compressImage,
    getFileSizeInMb
} from "../../helpers/index.js";

export default {
    name: "GenerateAiVideoForm",
    props: {
        currentRoute: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentMode: 'convert_URL',  // Default mode
            isActive: false,
            isDragging: false,
            urlInput: '',
            promptInput: '',
            isUploading: false,
            uploadError: false,
            images: [],
            urlErrors: [],
            promptErrors: [],
            csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            urlLabelTexts: [
                "Blog Post to Video",
                "News Story to Video",
                "Product Page to Video",
                "Event Page to Video",
                "Shop Offer to Video",
                "Service Page to Video",
                "Article to Video",
                "Promotion to Video",
                "Job Ad to Video",
                "Shop Listing to Video",
                "Website to Video",
                "Discount Offer to Video",
                "URL to Video",
                "Web Page to Video"
            ],
            promptLabelTexts: [
                "Announcement to Video",
                "Social Media Post to Video",
                "Images to Video",
                "Message to Video",
                "Invitation to Video",
                "Text Post to Video",
                "Photo Post to Video",
                "Quote to Video",
                "Story Post to Video",
                "Greeting Card to Video",
                "Social Media Post to Video",
                "Event Announcement to Video",
                "Promotion to Video",
                "Status Update to Video"
            ],
            currentUrlLabel: "Product Page to Video",
            currentPromptLabel: "Announcement to Video"
        };
    },
    created() {
        this.currentMode = this.currentRoute === 'landings.prompt-to-video' ? 'convert_PROMPT' : 'convert_URL';

        // Start the interval to change the labels every 4 seconds
        this.startLabelRotation();
    },
    methods: {
        startLabelRotation() {
            setInterval(() => {
                this.currentUrlLabel = this.getRandomLabel(this.urlLabelTexts);
                this.currentPromptLabel = this.getRandomLabel(this.promptLabelTexts);
            }, 2400); // Change every 4 seconds
        },
        getRandomLabel(labels) {
            const randomIndex = Math.floor(Math.random() * labels.length);
            return labels[randomIndex];
        },
        showUrlForm() {
            this.currentMode = 'convert_URL';
            this.isActive = false;  // Remove active state when switching modes
        },
        showPromptForm() {
            this.currentMode = 'convert_PROMPT';
        },
        activatePrompt() {
            this.isActive = true;
        },
        deactivatePrompt() {
            this.isActive = false;
        },
        submitUrlForm() {
            console.log('URL Form Submitted:', this.urlInput);
            this.urlErrors = [];
            const formData = new FormData();
            formData.append('url', this.urlInput);
            let token = document.querySelector('meta[name="api-token"]');
            axios.post('/api/ai-generate/convert-url', formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':`Bearer ${token.content}`
                },
                withCredentials: true // Ensures cookies are sent with the request (like fetch's 'same-origin')
            })
                .then(response => {
                    const data = response.data;
                    if (data.errors) {
                        this.urlErrors = Object.values(data.errors).flat();
                    } else {
                        window.location.href = data.redirect;
                    }
                })
                .catch(error => {
                    console.error('Error submitting URL form:', error);
                    if (error.response && error.response.data.errors) {
                        this.urlErrors = Object.values(error.response.data.errors).flat();
                    }
                });
        },
        submitPromptForm() {
            this.promptErrors = [];
            const formData = new FormData();
            formData.append('prompt', this.promptInput);
            formData.append('url', this.urlInput);
            let token = document.querySelector('meta[name="api-token"]');
            this.images.forEach((image, index) => {
                formData.append(`images[${index}][file]`, image.file);
                formData.append(`images[${index}][rotation]`, image.rotation);
                formData.append(`images[${index}][order]`, index); // Preserving order
            });

            axios.post('/api/ai-generate/convert-prompt', formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization':`Bearer ${token.content}`
                },
                withCredentials: true // Ensures cookies are sent with the request
            })
                .then(response => {
                    const data = response.data;
                    if (data.errors) {
                        this.promptErrors = Object.values(data.errors).flat();
                    } else {
                        window.location.href = data.redirect;
                    }
                })
                .catch(error => {
                    console.error('Error submitting Prompt form:', error);
                    if (error.response && error.response.data.errors) {
                        this.promptErrors = Object.values(error.response.data.errors).flat();
                    }
                });
        },
        uploadImages() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            this.processFiles(event.target.files);
        },
        handleDragOver(event) {
            this.isDragging = true;
        },
        handleDragLeave() {
            this.isDragging = false;
        },
        handleDrop(event) {
            this.isDragging = false;
            this.processFiles(event.dataTransfer.files);
        },
        async processFiles(files) {
            const allowedFormats = ['image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];
            const maxFileSize = typeof __MAX_FILE_SIZE_UPLOAD__ !== 'undefined' ? parseInt(__MAX_FILE_SIZE_UPLOAD__) : 3; // Size limit in MB

            this.isUploading = true;
            this.uploadError = false;

            // Helper function to compress images
            const compressFile = (file) => {
                return new Promise((resolve) => {
                    compressImage(file, 2400, 2400, 1, (compressedBlob) => {
                        const compressedFile = new File([compressedBlob], "compressed_" + file.name, {
                            type: compressedBlob.type,
                            lastModified: new Date().getTime()
                        });
                        resolve(compressedFile);
                    });
                });
            };

            // Process each file
            const filePromises = Array.from(files).map(async (file) => {
                if (allowedFormats.includes(file.type)) {
                    const fileSizeMB = getFileSizeInMb(file);

                    // Compress file if it's larger than the max size
                    let processedFile = file;
                    if (fileSizeMB > maxFileSize) {
                        processedFile = await compressFile(file);
                    }

                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.images.push({
                            id: Date.now() + Math.random(),
                            file: processedFile,
                            url: e.target.result,
                            rotation: 0
                        });
                    };
                    reader.readAsDataURL(processedFile);
                } else {
                    this.uploadError = true;
                }
            });

            // Await all file processing
            await Promise.all(filePromises);

            // Finalize the upload process
            this.isUploading = false;
        },
        moveImageLeft(index) {
            if (index > 0) {
                const temp = this.images[index];
                this.images.splice(index, 1);
                this.images.splice(index - 1, 0, temp);
            }
        },
        moveImageRight(index) {
            if (index < this.images.length - 1) {
                const temp = this.images[index];
                this.images.splice(index, 1);
                this.images.splice(index + 1, 0, temp);
            }
        },
        rotateImage(index, direction) {
            const rotationAmount = direction === 'left' ? -90 : 90;
            this.images[index].rotation = (this.images[index].rotation + rotationAmount) % 360;
        },
        deleteImage(index) {
            this.images.splice(index, 1);
        },
        handleDocumentClick(event) {
            if (!this.$el.querySelector('.convert_input_PROMPT_block').contains(event.target)) {
                this.deactivatePrompt();
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentClick);
    }
};

</script>


<style scoped>
/* Include your CSS here */
.my_upload_block.dragging {
    border: 2px dashed #007bff;
    background-color: #f0f8ff;
}
</style>
